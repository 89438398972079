<template>
  <el-button @click="goBack">返回</el-button>
  <div class="transfer" style="text-align: center">
    <el-transfer
      v-model="rightValue"
      style="text-align: left; display: inline-block"
      filterable
      :titles="['所有系统', '已选系统']"
      :format="{
        noChecked: '${total}',
        hasChecked: '${checked}/${total}',
      }"
      :data="data"
      @change="handleChange"
    >
      <template #default="{ option }">
        <el-popover placement="top-start" title="详情" :width="300" trigger="hover">
          <div slot:content>
            <p>服务器名：{{ option.applicationName }}</p>
            <p>服务器api：{{ option.api }}</p>
            <p>描述：{{ option.description }}</p>
          </div>
          <template #reference>
            <span>{{ option.label }}</span>
          </template>
        </el-popover>
        <!-- <el-tooltip placement="top">
          <template #content> multiple lines<br />second line </template>
          <span>{{ option.label }}</span>
        </el-tooltip> -->
      </template>
      <template #right-footer>
        <el-button type="primary" class="transfer-footer" @click="save" size="small"
          >保存</el-button
        >
      </template>
    </el-transfer>
  </div>
  <!-- <div class="btn"> <el-button type="primary" @click="save">提交</el-button></div> -->
</template>

<script>
  import { defineComponent, onBeforeMount, reactive, toRefs } from 'vue';
  import { getALlSystem } from '@/api/system';
  import { userRelationSystem, getUserRelationSystem } from '@/api/userinfo';
  import { useRouter, useRoute } from 'vue-router';
  import { ElMessage } from 'element-plus';

  export default defineComponent({
    name: '关联用户',
    setup() {
      const route = useRoute();
      const router = useRouter();
      const userId = route.query.id;
      const state = reactive({
        data: [],
        selectData: [],
        rightValue: [],
        leftValue: [],
        // renderContent(h, option) {
        //   return h('span', { domProps: { title: option.label } }, option.label);
        //   return (
        //     <span>
        //       <el-tooltip class="item" effect="light" >
        //        <template slot:content> multiple lines<br />second line </template>
        //         <span>{option.label}</span>
        //       </el-tooltip>
        //     </span>
        //   );
        // },
      });
      // 查询所有系统
      const getAllData = async (params) => {
        console.log('1111');
        const res = await getALlSystem(params);
        if (res.code === 0) {
          state.data = [];
          for (let i = 0; i < res.data.length; i += 1) {
            state.data.push({
              key: res.data[i].id,
              label: res.data[i].name,
              applicationName: res.data[i].applicationName,
              description: res.data[i].description,
              api: res.data[i].api,
            });
          }
          console.log(res.data);
        }
      };
      // 查询右边的数据
      const getRelationSystem = async () => {
        const res = await getUserRelationSystem(userId);
        if (res.code === 0) {
          for (let i = 0; i < res.data.length; i += 1) {
            state.rightValue.push(res.data[i].systemId);
          }
          console.log(state.rightValue);
        }
        console.log(res);
      };
      // 保存绑定
      const save = async () => {
        console.log(state.rightValue);
        const res = await userRelationSystem(state.rightValue, userId);
        if (res.code === 0) {
          ElMessage({
            type: 'success',
            message: '您已关联成功',
          });
        }
      };
      const handleChange = (value, direction, movedKeys) => {
        console.log(value, direction, movedKeys);
      };
      onBeforeMount(() => {
        const params = {
          name: '',
        };
        getAllData(params);
        getRelationSystem();
      });
      const goBack = () => {
        router.go(-1);
      };
      return {
        ...toRefs(state),
        handleChange,
        getAllData,
        save,
        goBack,
        getRelationSystem,
      };
    },
  });
</script>

<style scoped>
  .transfer :deep(.el-transfer-panel) {
    width: 400px !important;
  }
  .btn {
    width: 60%;
    padding: 30px;
    text-align: center;
  }
</style>
